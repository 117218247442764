import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import image1 from "../../assets/events.png";
import mtn from "../../assets/mtn.png";
import airtel from "../../assets/airtel.png";
import visa from "../../assets/visa.png";
import paypal from "../../assets/paypal.png";

const DonateSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const [amount, setAmount] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const paypalContainerRef = useRef(null); // Ref to track PayPal button container

  useEffect(() => {
    // Cleanup old PayPal buttons to prevent duplication
    if (paypalContainerRef.current) {
      paypalContainerRef.current.innerHTML = "";
    }

    // Initialize PayPal buttons
    if (window.paypal) {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          if (!amount || isNaN(amount) || Number(amount) <= 0) {
            setError("Please enter a valid donation amount.");
            return;
          }
          if (!fullName || !email) {
            setError("Please fill out all fields.");
            return;
          }

          setError(""); // Clear error message if validation passes

          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: parseFloat(amount).toFixed(2),
                  currency_code: "USD",
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            alert(`Thank you for your donation, ${details.payer.name.given_name}!`);
          });
        },
        onError: (err) => {
          console.error("PayPal Button Error:", err);
          alert("Something went wrong during the donation process. Please try again.");
        },
      }).render(paypalContainerRef.current); // Render PayPal buttons in the container
    }
  }, [amount, fullName, email]); // Re-run whenever the `amount`, `fullName`, or `email` changes

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    if (isNaN(e.target.value) || Number(e.target.value) <= 0) {
      setError("Please enter a valid donation amount.");
    } else {
      setError(""); // Clear error if input is valid
    }
  };

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#fff] px-6 md:px-12 lg:px-20 transform transition-transform duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <div className="max-w-8xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
        {/* Left Side: Image and Description */}
        <div className="space-y-6">
          <img
            src={image1}
            alt="Donate"
            className="rounded-lg object-cover w-full h-64 lg:h-96"
          />
          <p className="text-gray-700 font-noto-sans text-base md:text-lg leading-relaxed text-left">
            Your contribution, whether small or large, makes a significant impact.
            By donating to our cause, you're helping us continue our mission and
            reach more people who can benefit from our services.
          </p>
        </div>

        {/* Right Side: Form */}
        <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg space-y-6">
          <h1 className="text-3xl md:text-4xl font-bold text-[#2bbae6] font-big-shoulders text-left">
            Donate
          </h1>

          <form className="space-y-6">
            {/* Full Name */}
            <div>
              <label
                htmlFor="fullName"
                className="block text-gray-600 text-sm mb-1"
              >
                Full Names
              </label>
              <input
                id="fullName"
                type="text"
                placeholder="Full Names"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-600 text-sm mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Donation Amount */}
            <div>
              <p className="text-gray-600 text-sm md:text-base mb-2">Amount</p>
              <input
                type="text"
                placeholder="Enter Donation Amount"
                value={amount}
                onChange={handleAmountChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Error Message */}
            {error && <p className="text-red-500 text-sm">{error}</p>}

            {/* Payment Method */}
            <div>
              <p className="text-gray-600 text-sm md:text-base mb-2">
                Payment Method
              </p>
              <div className="flex flex-wrap gap-4">
                {[mtn, airtel, visa, paypal].map((icon, index) => (
                  <img
                    key={index}
                    src={icon}
                    alt="Payment Method"
                    className="h-12 w-auto border border-gray-300 p-2 rounded-md"
                  />
                ))}
              </div>
            </div>
          </form>

          {/* PayPal Buttons */}
          <div ref={paypalContainerRef} id="paypal-button-container"></div>
        </div>
      </div>
    </section>
  );
};

export default DonateSection;
