import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitMembershipForm, resetState } from "../../redux/membershipSlice";
import MembershipCard from "./MembershipCard";
import FormField from "./FormField";
import CheckboxField from "./CheckboxField";
import professional from "../../assets/professional.png";
import rental from "../../assets/rental.png";
import company from "../../assets/company.png";
import coalition from "../../assets/coalition.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BecomeMemberPage = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.membership);

  const [form, setForm] = useState({
    membership: "",
    name: "",
    cvrNumber: "",
    billingInfo: "",
    contactPerson: "",
    title: "",
    email: "",
    phone: "",
    address: "",
    website: "",
    businessSector: "",
    mainInterests: "",
    regionsOfInterest: "",
    companySize: "",
    termsAccepted: false,
    logo: null,
  });

  const membershipOptions = [
    {
      img_src: company,
      title: "As a Company",
      description:
        "Join us as a company member and access exclusive resources.",
    },
    {
      img_src: professional,
      title: "As a Professional",
      description: "Become a professional member and grow your network.",
    },
    {
      img_src: coalition,
      title: "As a Member of our Coalition",
      description: "Join our coalition and collaborate with peers.",
    },
    {
      img_src: rental,
      title: "Rent an Office",
      description: "Get access to office space for your business needs.",
    },
  ];

  const handleCardClick = (title) => {
    setForm((prevForm) => ({ ...prevForm, membership: title }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setForm({
      ...form,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };

  const validateForm = () => {
    const requiredFields = [
      { name: "membership", label: "Membership Type" },
      { name: "name", label: "Company Name" },
      { name: "cvrNumber", label: "CVR Number" },
      { name: "billingInfo", label: "Billing Information" },
      { name: "contactPerson", label: "Membership Contact" },
      { name: "title", label: "Title" },
      { name: "email", label: "Email" },
      { name: "phone", label: "Phone Number" },
      { name: "address", label: "Address" },
    ];

    for (const field of requiredFields) {
      if (!form[field.name]) {
        toast.error(`${field.label} is required.`);
        return false;
      }
    }

    if (!form.termsAccepted) {
      toast.error("You must accept the terms and conditions.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("logo", form.logo);
    formData.append("membershipType", form.membership);
    formData.append("companyName", form.name);
    formData.append("cvrNumber", form.cvrNumber);
    formData.append("billingInfo", form.billingInfo);
    formData.append("membershipContact", form.contactPerson);
    formData.append("title", form.title);
    formData.append("email", form.email);
    formData.append("phoneNumber", form.phone);
    formData.append("address", form.address);
    formData.append("website", form.website);
    formData.append("businessSector", form.businessSector);
    formData.append("mainInterests", form.mainInterests);
    formData.append("regionsOfInterest", form.regionsOfInterest);
    formData.append("companySize", form.companySize);
    formData.append("termsAccepted", form.termsAccepted);

    dispatch(submitMembershipForm(formData));
  };

  useEffect(() => {
    if (success) {
      toast.success("Membership form submitted successfully!");
      dispatch(resetState());
      setForm({
        membership: "",
        name: "",
        cvrNumber: "",
        billingInfo: "",
        contactPerson: "",
        title: "",
        email: "",
        phone: "",
        address: "",
        website: "",
        businessSector: "",
        mainInterests: "",
        regionsOfInterest: "",
        companySize: "",
        termsAccepted: false,
        logo: null,
      });
    }

    if (error) {
      const errorMessage =
        typeof error === "string" ? error : "An unexpected error occurred.";
      toast.error(errorMessage);
    }
  }, [success, error, dispatch]);

  return (
    <div className="bg-[#fffff] text-gray-800 w-full">
      <div className="py-12 bg-[#E8F8FF] px-4 md:px-8 lg:px-12">
        <h1 className="text-3xl md:text-4xl font-bold text-green-600 font-big-shoulders">
          Become a Member
        </h1>
        <p className="text-md md:text-lg text-gray-700 mt-4 font-noto-sans">
          Join us and access exclusive resources and opportunities, including
          networking events, professional development workshops, and
          collaborative projects designed to empower and grow your impact in the
          industry.
        </p>

        <section className="py-16 rounded-lg px-4 md:px-6 lg:px-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
            {membershipOptions.map((option, index) => (
              <MembershipCard
                key={index}
                img_src={option.img_src}
                title={option.title}
                description={option.description}
                isSelected={form.membership === option.title}
                onClick={() => handleCardClick(option.title)}
              />
            ))}
          </div>
        </section>
      </div>
      <div className="container max-w-5xl py-8 px-4 md:px-8 lg:px-12">
        <section className="bg-[#F0F6EA] p-6 md:p-8 rounded-lg shadow-lg mt-10">
          <h2 className="text-2xl font-bold text-[#2bbae6] mb-6 font-big-shoulders">
            Fill This Form to Become a Member
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6 font-noto-sans">
            <div className="space-y-2">
              <label
                htmlFor="membership"
                className="block text-gray-700 font-medium"
              >
                Membership Type* (Select One)
              </label>
              <select
                id="membership"
                name="membership"
                value={form.membership}
                onChange={handleChange}
                className="w-full border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 py-3"
              >
                <option value="" disabled>
                  Select Membership Type
                </option>
                {membershipOptions.map((option) => (
                  <option key={option.title} value={option.title}>
                    {option.title}
                  </option>
                ))}
              </select>
            </div>

            <FormField
              label="Company Name*"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
            <FormField
              label="CVR Number*"
              name="cvrNumber"
              value={form.cvrNumber}
              onChange={handleChange}
            />
            <FormField
              label="Billing Information*"
              name="billingInfo"
              value={form.billingInfo}
              onChange={handleChange}
            />
            <FormField
              label="Contact Person*"
              name="contactPerson"
              value={form.contactPerson}
              onChange={handleChange}
            />
            <FormField
              label="Title*"
              name="title"
              value={form.title}
              onChange={handleChange}
            />
            <FormField
              label="Email*"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
            />
            <FormField
              label="Phone Number*"
              name="phone"
              type="tel"
              value={form.phone}
              onChange={handleChange}
            />
            <FormField
              label="Address*"
              name="address"
              value={form.address}
              onChange={handleChange}
            />
            <FormField
              label="Website"
              name="website"
              value={form.website}
              onChange={handleChange}
            />
            <FormField
              label="Business Sector"
              name="businessSector"
              value={form.businessSector}
              onChange={handleChange}
            />
            <FormField
              label="Main Interests"
              name="mainInterests"
              value={form.mainInterests}
              onChange={handleChange}
              placeholder="Your main interests"
            />
            <FormField
              label="Regions of Interest"
              name="regionsOfInterest"
              value={form.regionsOfInterest}
              onChange={handleChange}
              placeholder="Regions you're interested in"
            />
            <FormField
              label="Company Size"
              name="companySize"
              value={form.companySize}
              onChange={handleChange}
              placeholder="Number of employees"
            />
            <FormField
              label="Upload Logo"
              name="logo"
              type="file"
              onChange={handleChange}
            />
            <CheckboxField
              label="I accept the Terms and Conditions."
              name="termsAccepted"
              checked={form.termsAccepted}
              onChange={handleChange}
            />
            <button
              type="submit"
              disabled={loading}
              className="px-4 bg-blue-500 text-white font-bold py-3 rounded-md mt-6 w-full sm:w-auto"
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default BecomeMemberPage;
