import React from 'react';
import { useInView } from 'react-intersection-observer';

const BuyInnovationsSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#fffff] px-6 md:px-12 lg:px-20 transform transition-opacity duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
    >
      <div className="max-w-8xl mx-auto text-center md:text-left">
        <h1 className="text-3xl md:text-3xl font-bold text-[#2bbae6] mb-4 font-big-shoulders">
          Buy Our Innovations/Products
        </h1>
        <p className="text-md md:text-lg text-gray-700 mb-8 font-noto-sans">
          Explore a wide range of groundbreaking products and innovations. By supporting our crowdfunding campaign, you are helping to bring these products to life. 
          Whether you're interested in cutting-edge technology, sustainable solutions, or the latest in design, we have something for you.
        </p>
        <a 
          href="https://example-crowdfunding-site.com" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-blue-500 text-white font-bold py-3 px-6 rounded-md"
          aria-label="Visit our crowdfunding site"
        >
          Visit our crowdfunding site
        </a>
      </div>
    </section>
  );
};

export default BuyInnovationsSection;
