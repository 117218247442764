import React from "react";
import DonateSection from "./DonateSection";
import HireUsSection from "./HireUsSection";
import BuyInnovationsSection from "./BuyInnovationsSection";
import image1 from "../../assets/events.png";

const SupportUsPage = () => {
  return (
    <div className="bg-[#fffff] text-gray-800 w-full">
      <section className="py-14 bg-[#E8F8FF] px-6 lg:px-12">
        <div className="max-w-12xl mx-auto">
          {/* Section Title */}
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-green-600 mb-8 py-4 font-big-shoulders text-left">
            Support Us
          </h1>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-6">
            {/* Left: Image */}
            <img
              src={image1}
              alt="Support Us"
              className="rounded-lg object-cover w-full h-72 md:h-96 lg:h-[500px]"
            />

            {/* Right: Text Content */}
            <div className="flex flex-col justify-start">
              <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-[#2bbae6] font-big-shoulders text-left">
                How you can Support Us
              </h2>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-4 font-noto-sans leading-relaxed text-left">
              Your support is crucial to our mission. Whether through donations, hiring us, or purchasing our products, your involvement helps us create a meaningful impact. Every contribution, big or small, makes a difference
              </p>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-4 font-noto-sans leading-relaxed text-left">
                Below are the various ways you can help us continue our important work:
              </p>

              {/* Donation Section */}
              <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-green-600 mt-6">
                1. Donate to Our Cause
              </h3>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-2 font-noto-sans leading-relaxed text-left">
              Donations directly fund our initiatives, enabling us to continue our work and make a real-world impact. Contributions, both one-time and recurring, help sustain and grow our projects.
              </p>

              {/* Hire Us Section */}
              <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-green-600 mt-6">
                2. Hire Our Talented Team
              </h3>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-2 font-noto-sans leading-relaxed text-left">
                Hiring our team for your projects supports our mission while providing you with top-tier expertise. We specialize in various fields, offering services from consulting to product development.
              </p>

              {/* Buy Innovations Section */}
              <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-green-600 mt-6">
                3. Purchase Our Innovative Products
              </h3>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-2 font-noto-sans leading-relaxed text-left">
              Buying our products helps fund further development and supports our ongoing efforts to create positive change. Our products are designed to bring innovative solutions to various sectors.
              </p>

              {/* Additional Support Options */}
              <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-green-600 mt-6">
                4. Spread the Word
              </h3>
              <p className="text-sm md:text-base lg:text-lg text-gray-700 mt-2 font-noto-sans leading-relaxed text-left">
                If you're unable to donate, hire us, or buy our products, you can still help by sharing our mission. Spreading the word on social media and among your network amplifies our reach and brings more support.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Additional Sections */}
      <DonateSection />
      <HireUsSection />
      <BuyInnovationsSection />
    </div>
  );
};

export default SupportUsPage;
