import React, { useState } from "react";
import { motion } from "framer-motion"; // For smooth animations
import { FiCheckCircle, FiClock, FiInfo } from "react-icons/fi"; // Icons for statuses
import "swiper/css"; // Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import image1 from "../assets/intervation.png";
import image2 from "../assets/intervation1.png";

// Mock intervention data
const interventions = [
  {
    id: 1,
    title: "Mpazi Rehousing and Neighborhood Upgrading",
    description:
      "A project aimed at improving housing conditions and infrastructure in Mpazi. This includes rehousing residents, upgrading streets, and enhancing living standards.",
    images: [image1, image1],
    timeline: "2023-2025",
    status: "In Progress",
    priority: "High",
  },
  {
    id: 2,
    title: "Mooi, Mooier Middelland",
    description:
      "An urban beautification project in Middelland focused on enhancing public spaces, improving pedestrian access, and adding green zones.",
    images: [image2, image2], // Directly use the image imports
    timeline: "2021-2023",
    status: "Completed",
    priority: "Medium",
  },
];

const InterventionDetails = () => {
  const [selectedIntervention, setSelectedIntervention] = useState(interventions[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSelect = (id) => {
    const intervention = interventions.find((item) => item.id === id);
    setSelectedIntervention(intervention);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const { title, description, images, timeline, status, priority } = selectedIntervention;

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-gray-100 p-6">
      <div className="w-full mx-auto bg-white rounded-lg shadow-xl grid grid-cols-1 md:grid-cols-4 gap-8 p-6">
        {/* Sidebar: List of interventions */}
        <div className="bg-gray-800 text-white rounded-lg shadow-md p-6 col-span-1">
          <h2 className="text-xl font-bold mb-6">Interventions</h2>
          {interventions.map((intervention) => (
            <motion.button
              key={intervention.id}
              onClick={() => handleSelect(intervention.id)}
              whileHover={{ scale: 1.05 }}
              className={`block w-full text-left px-6 py-3 rounded-lg mb-4 transition-all ease-in-out duration-300 ${
                intervention.id === selectedIntervention.id
                  ? "bg-[#2bbae6]"
                  : "hover:bg-blue-500"
              }`}
            >
              {intervention.title}
            </motion.button>
          ))}
        </div>

        {/* Content Section */}
        <div className="col-span-1 md:col-span-3 p-6 bg-white rounded-lg shadow-md">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">{title}</h1>
          <p className="text-base md:text-lg text-gray-700 mb-6">{description}</p>

          {/* Image Gallery */}
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Image Gallery</h3>
            <Swiper spaceBetween={10} slidesPerView={1} className="rounded-lg">
              {images.map((image, idx) => (
                <SwiperSlide key={idx}>
                  <motion.img
                    src={image} // Use the correct image source
                    alt={`Intervention ${title} ${idx}`}
                    className="rounded-lg shadow-md w-full h-72 object-cover cursor-pointer hover:scale-105 transition-all ease-in-out duration-300"
                    onClick={() => handleImageClick(image)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Timeline and Status */}
          <div className="flex flex-wrap items-center gap-6 mb-6">
            <span className="flex items-center text-sm text-gray-700">
              <FiClock className="mr-2 text-gray-500" /> Timeline: {timeline}
            </span>
            <span
              className={`px-4 py-2 rounded-full text-white text-sm font-medium ${
                status === "Completed" ? "bg-green-600" : "bg-yellow-500"
              }`}
            >
              {status}
            </span>
            <span
              className={`px-4 py-2 rounded-full text-white text-sm font-medium ${
                priority === "High" ? "bg-red-600" : "bg-yellow-400"
              }`}
            >
              Priority: {priority}
            </span>
          </div>

          {/* Project Info */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="bg-blue-50 p-6 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Project Overview</h3>
              <p className="text-gray-600 text-sm">{description}</p>
            </div>
            <div className="bg-blue-50 p-6 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Contact Details</h3>
              <p className="text-gray-600 text-sm">For more information, contact us at:</p>
              <p className="text-[#2bbae6]">contact@intervention.com</p>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Image */}
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} size="lg">
        <ModalHeader toggle={() => setIsModalOpen(false)}>
          <FiInfo /> Image Details
        </ModalHeader>
        <ModalBody>
          <img
            src={selectedImage}
            alt="Selected Intervention"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InterventionDetails;
