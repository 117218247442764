import React from "react";
import { useInView } from "react-intersection-observer";
import { FiPlay } from "react-icons/fi"; // Play icon
import image1 from "../../assets/edited-1-1024x621.jpg";
import image2 from "../../assets/busin-meet.jpg";
import image3 from "../../assets/business2.jpg";

const images = [image1, image2, image3];

const HeroSection = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <section
      ref={ref}
      className={`relative h-screen transform transition-opacity duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      {/* Static Image Section */}
      <div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${images[0]})` }} // Displaying the first image
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content */}
        <div className="relative z-10 flex flex-col justify-start items-start h-full text-white pl-4 sm:pl-8 lg:pl-12 pt-[30%] sm:pt-[25%] md:pt-[20%]">
          {/* Heading */}
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight text-[#2bbae6] font-big-shoulders">
            Empowering Startups Through<br></br> Collaboration & Innovation
          </h1>
          {/* Description */}
          <p className="mt-6 text-lg sm:text-xl md:text-2xl max-w-2xl font-noto-sans leading-relaxed">
            We build coalitions to foster growth, provide dynamic hubs for
            innovation, and deliver tailored training to empower startups.
            Join us in building a stronger entrepreneurial ecosystem that
            drives success and sustainability.
          </p>

          {/* Buttons */}
          <div className="mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <button className="bg-[#2bbae6] hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg">
              Get Started
            </button>
            {/* Watch Video Button with YouTube Link */}
            <a
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 hover:text-blue-400 text-lg relative group"
            >
              <div className="w-10 h-10 bg-[#2bbae6] rounded-full flex items-center justify-center text-white group-hover:bg-blue-700 transition-all">
                <FiPlay className="text-lg" />
              </div>
              <span>Watch Video</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
