import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents, subscribeToEvent } from "../../redux/eventSlice";
import FeaturedEvent from "./FeaturedEvent";
import UpcomingEvents from "./UpcomingEvents";
import FeaturedEventSkeleton from "./FeaturedEventSkeleton";
import UpcomingEventsSkeleton from "./UpcomingEventsSkeleton";

const EventsPage = () => {
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector((state) => state.events);
  const [featuredEvent, setFeaturedEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [email, setEmail] = useState(""); // State for subscription email
  const [selectedEventId, setSelectedEventId] = useState(null); // Track selected event for subscription
  const [subscriptionError, setSubscriptionError] = useState(""); // Error state for subscription
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(""); // Success state for subscription

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  useEffect(() => {
    if (events.length > 0) {
      setFeaturedEvent(events[0]);
      setUpcomingEvents(events.slice(1));
    }
  }, [events]);

  const handleEventClick = (selectedEvent) => {
    const newUpcoming = [featuredEvent, ...upcomingEvents.filter((e) => e.id !== selectedEvent.id)];
    setFeaturedEvent(selectedEvent);
    setUpcomingEvents(newUpcoming);
    setSelectedEventId(selectedEvent.id); // Set the selected event for subscription
  };

  const handleSubscriptionSubmit = (e) => {
    e.preventDefault();
    if (!email || !selectedEventId) {
      setSubscriptionError("Please enter a valid email and select an event.");
      return;
    }

    dispatch(subscribeToEvent({ eventId: selectedEventId, email }))
      .then(() => {
        setSubscriptionSuccess("Successfully subscribed!");
        setSubscriptionError(""); // Clear error message
      })
      .catch((error) => {
        setSubscriptionError(error.message || "Failed to subscribe.");
        setSubscriptionSuccess(""); // Clear success message
      });
  };

  return (
    <div className="bg-[#fffff] text-gray-800 w-full">
      <main className="w-full max-w-[1440px] mx-auto py-12 px-4 md:px-8 grid grid-cols-1 lg:grid-cols-3 gap-8">
        {loading ? (
          <>
            <div className="lg:col-span-2">
              <FeaturedEventSkeleton />
            </div>
            <aside className="lg:col-span-1">
              <UpcomingEventsSkeleton />
            </aside>
          </>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : events.length > 0 ? (
          <>
            <div className="lg:col-span-2">
              {featuredEvent && (
                <FeaturedEvent
                  title={featuredEvent.title}
                  subtitle="Featured Event"
                  image={featuredEvent.imageUrl}
                  description={featuredEvent.description}
                />
              )}
            </div>
            <aside className="lg:col-span-1">
              <UpcomingEvents
                events={upcomingEvents}
                onEventClick={handleEventClick}
              />
            </aside>
            {/* Subscription Form */}
            {/* <div className="lg:col-span-1 mt-8">
              <h2 className="text-xl font-semibold mb-4">Subscribe to Event</h2>
              {subscriptionSuccess && <p className="text-green-500">{subscriptionSuccess}</p>}
              {subscriptionError && <p className="text-red-500">{subscriptionError}</p>}
              <form onSubmit={handleSubscriptionSubmit} className="flex flex-col space-y-4">
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="p-2 border border-gray-300 rounded"
                />
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded"
                >
                  Subscribe
                </button>
              </form>
            </div> */}
          </>
        ) : (
          <p>No events available.</p>
        )}
      </main>
    </div>
  );
};

export default EventsPage;
