import React from "react";
import { useInView } from "react-intersection-observer";
import SkeletonLoader from "./SkeletonLoader";  // Import SkeletonLoader

const FeaturedEvent = ({ title, subtitle, image, description }) => {
  const { ref: imageRef, inView: imageInView } = useInView({ threshold: 0.2 });
  const { ref: textRef, inView: textInView } = useInView({ threshold: 0.2 });

  return (
    <section className="w-full">
      {title ? (
        <>
          <h1
            ref={textRef}
            className={`text-3xl md:text-4xl font-bold text-green-600 mb-4 md:mb-6 font-big-shoulders transform transition-transform duration-1000 ${
              textInView ? "scale-100" : "scale-95"
            }`}
          >
            {title}
          </h1>
          <h2 className="text-xl md:text-2xl font-semibold text-[#2bbae6] mb-4 font-big-shoulders">
            {subtitle}
          </h2>
          <img
            ref={imageRef}
            src={image}
            alt="Featured Event"
            className={`w-full h-48 md:h-96 object-cover mb-6 transform transition-transform duration-1000 ${
              imageInView ? "scale-100" : "scale-95"
            }`}
          />
          <div
            className="text-gray-700 leading-relaxed text-base md:text-lg font-noto-sans"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </>
      ) : (
        <>
          <SkeletonLoader height="h-96" margin="mb-6" /> {/* Image Skeleton */}
          <SkeletonLoader height="h-10" margin="mb-4" /> {/* Title Skeleton */}
          <SkeletonLoader height="h-6" margin="mb-6" /> {/* Subtitle Skeleton */}
          <SkeletonLoader height="h-24" margin="mb-6" /> {/* Description Skeleton */}
        </>
      )}
    </section>
  );
};

export default FeaturedEvent;
