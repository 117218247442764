import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { fetchInterventions } from "../../redux/interventionSlice";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

const SkeletonLoader = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
    {[1, 2, 3, 4].map((key) => (
      <div
        key={key}
        className="bg-white shadow-lg p-6 md:p-8 rounded-lg animate-pulse h-72 md:h-[450px] flex flex-col justify-between"
      >
        <div className="h-8 bg-gray-300 rounded w-3/4 mb-4"></div>
        <div className="w-full h-48 md:h-64 bg-gray-300 mb-6 rounded-lg"></div>
        <div className="h-4 bg-gray-300 rounded w-full"></div>
      </div>
    ))}
  </div>
);

const InterventionsSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const dispatch = useDispatch();
  const { interventions, loading, error } = useSelector(
    (state) => state.interventions
  );

  useEffect(() => {
    dispatch(fetchInterventions());
  }, [dispatch]);

  // Limit the number of cards to 2
  const limitedInterventions = interventions.slice(0, 2);

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  if (loading) {
    return (
      <section className="py-20 bg-[#E8F8FF] px-6 md:px-12 lg:px-20 min-h-[100vh]">
        <h2 className="text-3xl md:text-4xl font-bold text-green-600 mb-8 font-big-shoulders text-left py-12">
          Explore Our Interventions
        </h2>
        <div className="container mx-auto">
          <SkeletonLoader />
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-20 bg-[#E8F8FF] px-6 md:px-12 lg:px-20 min-h-[100vh] text-center">
        <p className="text-red-600 text-lg">Error: {error}</p>
      </section>
    );
  }

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#E8F8FF] px-6 md:px-12 lg:px-20 min-h-[100vh] transform transition-transform duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <h2 className="text-3xl md:text-4xl font-bold text-green-600 mb-8 font-big-shoulders text-left py-12">
        Explore Our Interventions
      </h2>
      <div className="container mx-auto max-w-7xl">
        {/* Frame Around All Interventions */}
        <div className="border-3 border-[fff] bg-white shadow-2xl rounded-lg p-8 md:p-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 w-full">
            {limitedInterventions.map((intervention, index) => (
              <div
                key={index}
                className="bg-white shadow-lg p-6 md:p-8 rounded-lg transform transition-transform duration-500 hover:scale-105 flex flex-col justify-between h-full"
              >
                <h3 className="text-2xl md:text-3xl font-bold mb-4 font-big-shoulders text-center">
                  {intervention.title}
                </h3>
                <div className="flex flex-col flex-grow">
                  <img
                    src={intervention.imageUrl}
                    alt={intervention.title}
                    className="w-full h-48 md:h-56 lg:h-64 object-cover mb-6 rounded-lg"
                  />
                  <div
                    className="text-gray-800 font-noto-sans text-sm md:text-lg leading-relaxed flex-grow"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(
                        intervention.description.length > 100
                          ? `${intervention.description.substring(0, 100)}...`
                          : intervention.description
                      ),
                    }}
                  ></div>
                  <Link
                    to={`/interventions/`}
                    className="text-blue-500 font-bold mt-4"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InterventionsSection;
