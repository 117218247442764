import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitHireRequest, resetState } from "../../redux/hireSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import travel from "../../assets/travel.png";
import art from "../../assets/art.png";
import urban from "../../assets/urban.png";
import mini from "../../assets/mini.png";
import interns from "../../assets/interns.png";
import construction from "../../assets/construction.png";

const HireUsSection = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.hire);

  const [form, setForm] = useState({
    category: "",
    projectName: "",
    scope: "",
    description: "",
  });

  const [selectedPartner, setSelectedPartner] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });

    // Set the selected partner when category changes
    if (name === "category") {
      setSelectedPartner(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    const { category, projectName, scope, description } = form;
    if (!category || !projectName || !scope || !description) {
      toast.error("All fields are required.");
      return;
    }

    // Dispatch the form data
    dispatch(submitHireRequest(form));
  };

  useEffect(() => {
    if (success) {
      toast.success("Hire request submitted successfully!");
      dispatch(resetState());
      setForm({
        category: "",
        projectName: "",
        scope: "",
        description: "",
      });
    }
    if (error) {
      toast.error(error);
    }
  }, [success, error, dispatch]);

  // Partner links
  const partnerLinks = [
    { image: travel, name: "Company A", link: "https://travel.com" },
    { image: art, name: "Company B", link: "https://art.com" },
    { image: urban, name: "Company C", link: "https://urban.com" },
    { image: mini, name: "Company D", link: "https://mini.com" },
    { image: interns, name: "Company E", link: "https://interns.com" },
    { image: construction, name: "Company F", link: "https://construction.com" },
  ];

  return (
    <section className="py-20 bg-[#fffff] px-6 lg:px-20 transform transition-transform duration-1000">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
        {/* Left Side: Form */}
        <div>
          <h2 className="text-3xl md:text-4xl font-bold text-green-600 mb-6 font-big-shoulders text-left">
            Hire Us
          </h2>
          <p className="text-gray-700 text-base md:text-lg mb-8 font-noto-sans leading-relaxed text-left">
            Support by hiring us for a professional project. Fill out the form below to get started.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Category */}
            <select
              name="category"
              value={form.category}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Choose from list</option>
              <option value="Company A">Ubuntu Travels</option>
              <option value="Company B">Ubuntu Art Gallery</option>
              <option value="Company C">Urban sustainability Rwanda</option>
              <option value="Company D">The Minimalist</option>
              <option value="Company E">Ubuntu Internships</option>
              <option value="Company F">Ubuntu construction</option>
            </select>
            {/* Project Name */}
            <input
              type="text"
              name="projectName"
              value={form.projectName}
              onChange={handleChange}
              placeholder="Project Name"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {/* Scope */}
            <input
              type="text"
              name="scope"
              value={form.scope}
              onChange={handleChange}
              placeholder="Scope"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {/* Description */}
            <textarea
              name="description"
              value={form.description}
              onChange={handleChange}
              placeholder="Description"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
            />
            {/* Submit Button */}
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 text-white font-bold py-3 rounded-md mt-4 px-10 hover:bg-[#2bbae6] focus:outline-none"
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>

        {/* Right Side: Partners */}
        <div className="grid gap-6 items-center justify-center">
          <div className="grid gap-6">
            {partnerLinks.map((partner, index) => (
              <a
                key={index}
                href={partner.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`p-2 border border-gray-300 bg-white rounded-lg shadow-sm flex items-center justify-center transition-all duration-300 ${
                  selectedPartner === partner.name
                    ? "border-4 border-green-600 bg-green-100"
                    : "hover:border-4 hover:border-blue-500 hover:bg-blue-100"
                }`}
              >
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="h-16 w-auto object-contain"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HireUsSection;
