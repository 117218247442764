import React from 'react';

const CheckboxField = ({ label, name, checked, onChange }) => (
  <div className="flex items-center mt-4">
    <input
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      className="h-4 w-4 text-[#2bbae6] focus:ring-blue-500 border-gray-300 rounded"
    />
    <label className="ml-2 text-gray-700 font-noto-sans text-sm sm:text-base">
      {label}
    </label>
  </div>
);

export default CheckboxField;
