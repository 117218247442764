import React from 'react';
import { useInView } from 'react-intersection-observer';

const AboutSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <div
      ref={ref}
      className={`bg-blue-50 py-12 transform transition-all duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
    >
      <div className="container mx-auto px-6 space-y-12">
        {/* About Us */}
        <div>
          <h2 className="text-green-600 text-3xl font-bold md:text-4xl font-big-shoulders">About Us</h2>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            We are a team dedicated to building a thriving ecosystem for startups. By forming strategic coalitions, providing innovative hubs, and delivering specialized training, we empower entrepreneurs to achieve sustainable growth. Our goal is to help startups flourish by providing them with the tools, knowledge, and resources they need to succeed in today's fast-paced market.
          </p>
        </div>

        {/* Our Mission */}
        <div>
          <h3 className="text-[#2bbae6] text-2xl font-semibold font-big-shoulders">Our Mission</h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Our mission is to foster innovation through collaboration. We strive to create a network of startups, investors, and experts who work together to drive positive change. By offering resources such as training programs, mentorship, and access to funding, we aim to accelerate the growth of startups and turn their visionary ideas into impactful realities.
          </p>
        </div>

        {/* Our Vision */}
        <div>
          <h3 className="text-[#2bbae6] text-2xl font-semibold font-big-shoulders">Our Vision</h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Our vision is to become the leading catalyst for startup success globally. We aim to be the go-to platform for entrepreneurs seeking support in every phase of their journey. Through our hubs and tailored training programs, we aspire to equip startups with the expertise and network they need to thrive in competitive markets.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
