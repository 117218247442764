import React from 'react';
import TeamMemberCard from './TeamMemberCard';
import image1 from '../../assets/intern.png';
import image2 from '../../assets/intern2.png';
import { useInView } from 'react-intersection-observer';

const teamMembers = [
  { name: 'John Doe', role: 'Design Intern', image: image1 },
  { name: 'Jane Smith', role: 'Design Intern', image: image2 },
  { name: 'Mark Johnson', role: 'Design Intern', image: image1 },
  { name: 'Lucy Brown', role: 'Design Intern', image: image2 },
  { name: 'Chris White', role: 'Design Intern', image: image1 },
  { name: 'Emily Davis', role: 'Design Intern', image: image2 },
  { name: 'Michael Clark', role: 'Design Intern', image: image1 },
  { name: 'Sarah Lewis', role: 'Design Intern', image: image2 },
];

const TeamSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });

  return (
    <div
      ref={ref}
      className={`bg-blue-50 py-12 transform transition-all duration-1000 ${
        inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
      }`}
    >
      <div className="container mx-auto px-6 space-y-8">
        {/* Section Header */}
        <div>
          <h3 className="text-[#2bbae6] text-2xl font-semibold font-big-shoulders">
            Our Talented Team
          </h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Meet the creative minds behind our designs. Our team is dedicated to bringing innovative solutions and top-tier design to every project. We work collaboratively to ensure success in every detail.
          </p>
        </div>

        {/* Team Members Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 py-8">
          {teamMembers.map((member, index) => (
            <TeamMemberCard
              key={index}
              name={member.name}
              role={member.role}
              image={member.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
