import React, { useState } from 'react';
import image1 from '../assets/busin-meet.jpg';
import image2 from '../assets/meetingg.png';
import image3 from '../assets/foremeeting.jpg';
import imageSlide from '../assets/business2.jpg';
import { Link } from 'react-router-dom';

const CollaborationPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '', image: '' });

  const handleOpenModal = (title, content, image) => {
    setModalContent({ title, content, image });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-gradient-to-r from-green-400 to-blue-500 py-10 text-white text-center">
        <h1 className="text-4xl font-bold transition-transform transform hover:scale-105">Collaborate with Ubuntu Valley</h1>
        <p className="mt-4 text-lg opacity-90 hover:opacity-100 transition-opacity">Join us in empowering the African tech ecosystem through innovation and collaboration.</p>
        <button className="mt-6 px-6 py-3 bg-white text-blue-500 font-semibold rounded-md hover:bg-blue-100 shadow-lg hover:shadow-xl transition-shadow">
          Get Started
        </button>
      </header>

      <section className="container mx-auto py-16 px-4">
        <h2 className="text-3xl font-semibold text-center text-gray-800">Ways to Collaborate</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
          <div
            className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() =>
              handleOpenModal(
                'Partnerships',
                'Partner with us to drive innovation in the African tech landscape.',
                image1
              )
            }
          >
            <img src={image1} alt="Partnerships" className="w-full h-40 object-cover rounded-md mb-4" />
            <h3 className="text-xl font-semibold text-gray-800">Partnerships</h3>
            <p className="mt-4 text-gray-600">Partner with us to drive innovation in the African tech landscape.</p>
          </div>

          <div
            className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() =>
              handleOpenModal(
                'Open Projects',
                'Contribute your expertise to open-source and community-driven projects.',
                image2
              )
            }
          >
            <img src={image2} alt="Open Projects" className="w-full h-40 object-cover rounded-md mb-4 hover:opacity-90 transition-opacity" />
            <h3 className="text-xl font-semibold text-gray-800">Open Projects</h3>
            <p className="mt-4 text-gray-600">Contribute your expertise to open-source and community-driven projects.</p>
          </div>

          <div
            className="bg-white shadow-md rounded-lg p-6 text-center transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() =>
              handleOpenModal(
                'Community Initiatives',
                'Join our efforts to empower local tech talent and communities.',
                image3
              )
            }
          >
            <img src={image3} alt="Community Initiatives" className="w-full h-40 object-cover rounded-md mb-4 hover:brightness-110 transition-brightness" />
            <h3 className="text-xl font-semibold text-gray-800">Community Initiatives</h3>
            <p className="mt-4 text-gray-600">Join our efforts to empower local tech talent and communities.</p>
          </div>
        </div>
      </section>

      <section className="bg-gray-200 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center text-gray-800">Success Stories</h2>
          <p className="mt-4 text-center text-gray-600">Discover how collaboration has driven impact in the African tech space.</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
            <div
              className="bg-white shadow-md rounded-lg p-6 transform hover:scale-105 transition-transform cursor-pointer"
              onClick={() =>
                handleOpenModal(
                  'Project A',
                  'A transformative project that connected local talent with global opportunities.',
                  imageSlide
                )
              }
            >
              <img src={imageSlide} alt="Project A" className="w-full h-40 object-cover rounded-md mb-4 hover:opacity-90 transition-opacity" />
              <h3 className="text-xl font-semibold text-gray-800">Project A</h3>
              <p className="mt-4 text-gray-600">A transformative project that connected local talent with global opportunities.</p>
            </div>

            <div
              className="bg-white shadow-md rounded-lg p-6 transform hover:scale-105 transition-transform cursor-pointer"
              onClick={() =>
                handleOpenModal(
                  'Project B',
                  'Empowering women in tech through education and mentorship programs.',
                  image2
                )
              }
            >
              <img src={image2} alt="Project B" className="w-full h-40 object-cover rounded-md mb-4 hover:brightness-110 transition-brightness" />
              <h3 className="text-xl font-semibold text-gray-800">Project B</h3>
              <p className="mt-4 text-gray-600">Empowering women in tech through education and mentorship programs.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-r from-blue-500 to-green-400 py-16 text-white text-center">
        <h2 className="text-3xl font-semibold transition-transform transform hover:scale-110">Ready to Collaborate?</h2>
        <p className="mt-4">Reach out to us to start your journey with Ubuntu Valley.</p>
        <Link to="/contact"><button className="mt-6 px-6 py-3 bg-white text-blue-500 font-semibold rounded-md hover:bg-blue-100 shadow-lg hover:shadow-xl transition-shadow">
          Contact Us
        </button></Link>
      </section>

      {isModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50 transition-opacity duration-500 opacity-100">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full overflow-y-auto max-h-[70vh] transition-all duration-500 transform hover:scale-105">
            <button
              className="absolute top-4 right-4 text-gray-700 text-xl"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h2 className="text-2xl font-semibold mb-4">{modalContent.title}</h2>
            <img
              src={modalContent.image}
              alt={modalContent.title}
              className="w-full h-48 object-cover rounded-md mb-4"
            />
            <p className="text-gray-700">{modalContent.content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollaborationPage;
