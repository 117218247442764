import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { useInView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { fetchHubs } from "../../redux/hubSlice";
import { Link } from "react-router-dom";

const SkeletonLoader = () => (
  <div className="bg-white shadow-xl flex flex-col md:flex-row w-full max-w-5xl items-stretch h-[60vh] md:h-[70vh] rounded-lg overflow-hidden animate-pulse">
    <div className="w-full md:w-1/2 h-full bg-gray-300"></div>
    <div className="p-6 md:p-10 w-full md:w-1/2 h-full flex flex-col justify-start">
      <div className="h-8 bg-gray-300 rounded w-3/4 mb-4"></div>
      <div className="h-6 bg-gray-300 rounded w-2/3 mb-4"></div>
      <div className="h-4 bg-gray-300 rounded w-1/2"></div>
    </div>
  </div>
);

const HubSection = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const { hubs, loading, error } = useSelector((state) => state.hubs);

  useEffect(() => {
    dispatch(fetchHubs());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hubs.length > 0) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % hubs.length);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [hubs]);

  const sanitizeHtml = (html) => DOMPurify.sanitize(html);

  if (loading) {
    return (
      <section className="py-20 bg-[#ffffff] px-6 md:px-12 lg:px-20">
        <h2 className="text-3xl md:text-4xl font-bold text-[#2bbae6] mb-8 font-big-shoulders">
          Explore Our Hubs
        </h2>
        <div className="container mx-auto flex justify-center">
          <SkeletonLoader />
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-20 bg-[#ffffff] px-6 md:px-12 lg:px-20 text-center">
        <p className="text-red-500 text-lg">Error: {error}</p>
      </section>
    );
  }

  if (hubs.length === 0) {
    return (
      <section className="py-20 bg-[#ffffff] px-6 md:px-12 lg:px-20 text-center">
        <p className="text-gray-500 text-lg">
          No hubs available at the moment.
        </p>
      </section>
    );
  }

  return (
    <section
      ref={ref}
      className={`py-20 bg-[#ffffff] px-6 md:px-12 lg:px-20 transform transition-transform duration-1000 ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <h2 className="text-3xl md:text-4xl font-bold text-[#2bbae6] mb-8 font-big-shoulders">
        Explore Our Hubs
      </h2>
      <div className="container mx-auto">
        <div className="relative flex items-center justify-center">
          {/* Previous Button */}
          <button
            onClick={() =>
              setCurrentIndex(
                (prevIndex) => (prevIndex - 1 + hubs.length) % hubs.length
              )
            }
            className="absolute left-4 bg-opacity-75 text-blue-500 hover:bg-opacity-100 text-3xl md:text-4xl z-10 p-2 rounded-full"
          >
            &#8249;
          </button>

          {/* Hub Card */}
          <Link to={`/hub/${hubs[currentIndex].id}`}>
            <div
              className={`bg-white shadow-xl transform transition-transform duration-500 ${
                inView ? "scale-105" : "scale-100"
              } flex flex-col md:flex-row w-full max-w-5xl items-stretch h-[60vh] md:h-[70vh] rounded-lg overflow-hidden`}
            >
              <img
                src={hubs[currentIndex].imageUrl}
                alt={hubs[currentIndex].title}
                className="w-full md:w-1/2 h-full object-cover"
              />
              <div className="p-6 md:p-10 w-full md:w-1/2 h-full flex flex-col justify-start text-left">
                <h3 className="text-2xl md:text-3xl font-bold text-green-600 font-big-shoulders">
                  {hubs[currentIndex].title}
                </h3>
                <p
                  className="mt-4 text-gray-700 leading-relaxed text-base md:text-lg font-noto-sans"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(hubs[currentIndex].description),
                  }}
                ></p>
              </div>
            </div>
          </Link>

          {/* Next Button */}
          <button
            onClick={() =>
              setCurrentIndex((prevIndex) => (prevIndex + 1) % hubs.length)
            }
            className="absolute right-4 bg-opacity-75 text-blue-500 hover:bg-opacity-100 text-3xl md:text-4xl z-10 p-2 rounded-full"
          >
            &#8250;
          </button>
        </div>
      </div>
    </section>
  );
};

export default HubSection;
