import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeamMembers } from "../../redux/teamSlice";
import BoardMemberCard from "./BoardMemberCard";
import { useInView } from "react-intersection-observer";

const BoardSection = () => {
  const dispatch = useDispatch();
  const { members, loading, error } = useSelector((state) => state.team);
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, [dispatch]);

  return (
    <div
      ref={ref}
      className={`bg-blue-50 py-12 transform transition-all duration-1000 ${
        inView ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-10"
      }`}
    >
      <div className="container mx-auto px-6 space-y-8">
        {/* Board Section Header */}
        <div>
          <h3 className="text-[#2bbae6] text-2xl font-semibold font-big-shoulders">
            Meet Our Board
          </h3>
          <p className="text-gray-700 mt-4 leading-relaxed font-noto-sans">
            Our board members are key drivers of our vision and mission, bringing a wealth of experience and diverse expertise to the table. They are committed to supporting our efforts in empowering startups, fostering innovation, and creating a robust ecosystem for growth.
          </p>
        </div>

        {/* Loading or Error Handling */}
        {loading ? (
          <p>Loading team members...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 py-8">
            {members.map((member) => (
              <BoardMemberCard
                key={member.id}
                name={member.name}
                role={member.title}
                image={member.imageUrl}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardSection;
