import React, { useEffect, useState, useRef } from "react";
import image1 from '../assets/busin-meet.jpg';
import image2 from '../assets/meetingg.png';
import image3 from '../assets/foremeeting.jpg';
import imageSlide from '../assets/business2.jpg';

const HubsPage = () => {
  const hubs = [
    {
      id: 1,
      title: "Superhub Alpha",
      description:
        "Superhub Alpha is designed to foster collaborative business meetings and networking opportunities. With state-of-the-art facilities and a dynamic environment, it’s the perfect setting for entrepreneurs and professionals to brainstorm, innovate, and grow their ideas into actionable plans.",
      category: "Business Hub",
      backgroundUrl: image1,
      sideImages: [
        imageSlide,
        imageSlide,
        imageSlide,
        imageSlide,
      ],
    },
    {
      id: 2,
      title: "Co-Creation Hub",
      description:
        "At the Creative Leadership Hub, we cultivate visionary leaders. This space is dedicated to inspiring individuals to think creatively, collaborate effectively, and lead with purpose. Workshops, seminars, and one-on-one mentorships are just the beginning of your leadership journey.",
      category: "Leadership Hub",
      backgroundUrl: image2,
      sideImages: [
        imageSlide,
        imageSlide,
        imageSlide,
        imageSlide,
      ],
    },
    {
      id: 3,
      title: "Creative Leadership Hub",
      description:
        "At the Creative Leadership Hub, we cultivate visionary leaders. This space is dedicated to inspiring individuals to think creatively, collaborate effectively, and lead with purpose. Workshops, seminars, and one-on-one mentorships are just the beginning of your leadership journey.",
      category: "Leadership Hub",
      backgroundUrl: image2,
      sideImages: [
        imageSlide,
        imageSlide,
        imageSlide,
        imageSlide,
      ],
    },
    {
      id: 4,
      title: "Mindfulness Retreat",
      description:
        "The Mindfulness Retreat provides a serene environment to help individuals rejuvenate their minds and reconnect with their inner peace. Through guided meditation, yoga sessions, and wellness activities, this hub offers a peaceful escape from the demands of daily life.",
      category: "Wellness Hub",
      backgroundUrl: image3,
      sideImages: [
        imageSlide,
        imageSlide,
        imageSlide,
        imageSlide,
      ],
    },
  ];

  const [scrollY, setScrollY] = useState(0);
  const [visibleSections, setVisibleSections] = useState({});

  const sectionRefs = useRef(hubs.map(() => React.createRef()));

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  // IntersectionObserver to detect when each section is in view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = entry.target.dataset.index;
        setVisibleSections((prev) => ({
          ...prev,
          [index]: entry.isIntersecting, // Update visibility state based on intersection
        }));
      });
    });

    sectionRefs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.dataset.index = index; // Store index in data attribute
        observer.observe(ref.current); // Observe each section
      }
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-white text-gray-800">
      {hubs.map((hub, index) => (
        <section
          key={hub.id}
          ref={sectionRefs.current[index]}  // Reference for each section
          className="relative h-screen flex flex-col lg:flex-row items-center lg:items-stretch"
          style={{
            backgroundImage: `url(${hub.backgroundUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed", // Ensure the background stays fixed
          }}
        >
          {/* Background Image + Hub Description */}
          <div className="relative lg:w-2/3 h-full bg-black bg-opacity-50 flex items-center justify-center text-white px-8 lg:px-16 pt-8">
            {/* Text Content */}
            <div className="text-center lg:text-left max-w-2xl z-10">
              <h2 className="text-5xl font-bold mb-4">{hub.title}</h2>
              <p className="text-lg leading-relaxed">{hub.description}</p>
            </div>
          </div>

          {/* Side Images (Scrollable) */}
          <div className="lg:w-1/3 h-full overflow-hidden relative p-4">
            <div className="absolute top-0 left-0 w-full">
              {/* Larger Side Images */}
              {hub.sideImages.map((image, imgIndex) => (
                <img
                  key={imgIndex}
                  src={image}
                  alt={`Side Visual ${imgIndex + 1}`}
                  className="w-full max-w-sm rounded-lg shadow-lg mb-8"
                  style={{
                    transform: visibleSections[index]
                      ? `translateY(${scrollY * 0.3}px)` // Apply scroll effect when the section is visible
                      : "none", // No scroll effect if section is not visible
                    transition: "transform 0.1s ease-out",
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default HubsPage;
