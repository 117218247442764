import React, { useState } from "react";
import image1 from '../assets/busin-meet.jpg';
import imageSlide from '../assets/business2.jpg';

const HubDetailsPage = ({ hub }) => {
  return (
    <div className="bg-white text-gray-800">
      {/* Hub Details Section */}
      <section className="py-16">
        <div className="container mx-auto px-6 lg:px-4">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-green-600 text-center mb-8">
            {hub.title} Overview
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Left Column - Hub Details */}
            <div>
              <h3 className="text-2xl sm:text-3xl font-semibold text-green-600 mb-4">About {hub.title}</h3>
              <p className="text-lg leading-relaxed mb-6">{hub.longDescription}</p>

              <div className="mt-8">
                <h4 className="text-2xl font-semibold">Category:</h4>
                <p className="text-lg">{hub.category}</p>
              </div>

              <div className="mt-8">
                <h4 className="text-2xl font-semibold">Core Features:</h4>
                <ul className="list-disc pl-6 text-lg space-y-2">
                  {hub.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="space-y-6">
              {hub.sideImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Side Image ${index + 1}`}
                  className="w-full h-auto lg:h-[300px] object-cover rounded-lg shadow-lg"
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-6 lg:px-4 text-center">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 text-green-600">
            Join Us at {hub.title}
          </h2>
          <p className="text-lg mb-8">
            Whether you're looking to innovate, lead, or unwind, {hub.title} is the perfect place for you. Contact us to get started and become part of a dynamic community of like-minded individuals.
          </p>
          <button className="bg-[#2bbae6] text-white py-2 px-6 rounded-full text-xl hover:bg-blue-700 transition sm:text-lg">
            Get In Touch
          </button>
        </div>
      </section>
    </div>
  );
};

const HubTabs = () => {
  const [selectedHub, setSelectedHub] = useState(0);

  const hubs = [
    {
      id: 1,
      title: "Superhub Alpha",
      description: "Superhub Alpha is designed to foster collaborative business meetings and networking opportunities. With state-of-the-art facilities and a dynamic environment, it’s the perfect setting for entrepreneurs and professionals to brainstorm, innovate, and grow their ideas into actionable plans.",
      longDescription: "Superhub Alpha is more than just a meeting space; it’s a thriving ecosystem where innovative minds meet, collaborate, and elevate their businesses. Equipped with modern conference rooms, high-speed internet, and multimedia support, this hub offers all the tools you need for successful meetings, networking, and idea incubation. Whether you're an entrepreneur, small business owner, or corporate professional, Superhub Alpha provides the perfect environment to turn your ideas into reality.",
      category: "Business Hub",
      features: [
        "Modern, flexible meeting spaces",
        "High-speed internet access",
        "State-of-the-art audiovisual equipment",
        "24/7 access to facilities",
        "Event spaces for large gatherings",
        "Networking opportunities with entrepreneurs and professionals"
      ],
      backgroundUrl: image1,
      sideImages: [imageSlide, imageSlide],
    },
    {
      id: 2,
      title: "Co-Creation Hub",
      description: "Co-Creation Hub offers a collaborative environment where entrepreneurs, innovators, and creatives can come together to build new projects, share ideas, and co-create solutions to the world's biggest challenges.",
      longDescription: "The Co-Creation Hub is an inclusive space designed to bring together diverse talents and ideas. Whether you’re working on a startup or a social impact project, this hub offers the perfect blend of resources and community support to help you succeed. With dedicated brainstorming rooms, collaborative workspaces, and a vibrant atmosphere, this is where the next wave of innovation is born. Join us to create solutions that matter, and build connections that will take your ideas to the next level.",
      category: "Creative Hub",
      features: [
        "Creative workspaces for brainstorming and prototyping",
        "Shared resources and tools for innovation",
        "Collaborative projects with industry leaders",
        "A community of creatives, entrepreneurs, and social innovators",
        "Mentorship programs to guide your growth"
      ],
      backgroundUrl: image1,
      sideImages: [imageSlide, imageSlide],
    },
    {
      id: 3,
      title: "Creative Leadership Hub",
      description: "The Creative Leadership Hub is a dynamic space designed for leaders and change-makers to come together, exchange ideas, and develop leadership strategies that drive innovation and growth in the creative industries.",
      longDescription: "At the Creative Leadership Hub, we believe that great leadership is about more than just managing teams. It’s about inspiring creativity, driving innovation, and transforming industries. This hub provides a stimulating environment where creative leaders can collaborate, attend workshops, and participate in leadership development programs. Whether you’re a seasoned industry professional or an emerging creative leader, our space and programs are designed to help you unlock your full leadership potential and make a lasting impact.",
      category: "Leadership Hub",
      features: [
        "Leadership training and development programs",
        "Creative workshops and seminars",
        "Networking with industry leaders and innovators",
        "Dedicated meeting spaces for strategic planning",
        "A focus on sustainable and impactful leadership"
      ],
      backgroundUrl: image1,
      sideImages: [imageSlide, imageSlide, imageSlide],
    },
    {
      id: 4,
      title: "Mindfulness Retreat",
      description: "The Mindfulness Retreat offers a serene and peaceful environment for individuals to disconnect from the hustle and bustle of daily life, recharge, and explore personal growth through mindfulness and meditation.",
      longDescription: "Step into a world of calm and relaxation at the Mindfulness Retreat. Here, we offer a peaceful sanctuary away from the stresses of modern life, where you can reconnect with yourself through mindfulness, yoga, and meditation. Our retreat space is designed to promote inner peace and well-being, with a variety of classes, workshops, and events aimed at fostering personal growth and emotional balance. Whether you’re new to mindfulness or a seasoned practitioner, the Mindfulness Retreat provides the perfect environment to nurture your mind, body, and spirit.",
      category: "Wellness Hub",
      features: [
        "Guided meditation and mindfulness sessions",
        "Yoga and wellness workshops",
        "Private and group sessions for personal growth",
        "Tranquil natural surroundings for reflection and relaxation",
        "Community events focused on well-being"
      ],
      backgroundUrl: image1,
      sideImages: [imageSlide, imageSlide, imageSlide],
    },
  ];

  return (
    <div className="bg-white text-gray-800">
      {/* Hero Section */}
      <section
        className="relative w-full min-h-[500px] flex flex-col justify-center items-center text-center bg-cover bg-center"
        style={{ backgroundImage: `url(${image1})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-white px-6 lg:px-4">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold">Explore Our Innovative Hubs</h1>
          <p className="text-lg mt-4 max-w-2xl mx-auto">Discover a range of spaces designed to foster creativity, collaboration, and growth. Whether you're an entrepreneur, creative, or leader, our hubs offer the perfect environment to thrive.</p>
        </div>
      </section>

      <div className="bg-gray-100 py-6">
        <div className="container mx-auto px-6 lg:px-4 text-center">
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            {hubs.map((hub, index) => (
              <button
                key={hub.id}
                onClick={() => setSelectedHub(index)}
                className={`px-6 py-2 font-semibold text-lg ${selectedHub === index ? 'bg-[#2bbae6] text-white' : 'text-[#2bbae6]'}`}
              >
                {hub.title}
              </button>
            ))}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-3xl font-semibold mb-4 text-green-600">{hubs[selectedHub].title}</h2>
            <p className="text-lg">{hubs[selectedHub].description}</p>
            <button className="mt-4 text-blue-500">More Info</button>
          </div>
        </div>
      </div>

      <div className="p-6 mx-auto">
        <HubDetailsPage hub={hubs[selectedHub]} />
      </div>
    </div>
  );
};

export default HubTabs;
