import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../assets/Logo.png';

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <header
      className={`bg-white w-full z-10 py-4 transition-all duration-300 shadow-md ${
        isScrolled ? 'fixed top-0' : 'relative'
      }`}
    >
      <div className="mx-4 flex flex-col items-center">
        <div className="flex justify-between items-center w-full">
          {/* Logo Section */}
          <Link to="/" className="flex flex-col items-center">
            <img src={logo} alt="Ubuntu Valley" className="h-8 md:h-10" />
            <span
              className="mt-0 text-xs md:text-sm font-semibold text-gray-800 tracking-wide
              text-center font-serif text-[#2bbae6] drop-shadow-md"
            >
              Where the world becomes one
            </span>
          </Link>

          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden" onClick={toggleMenu}>
            {isMenuOpen ? (
              <RiCloseLine className="text-3xl cursor-pointer text-gray-800" />
            ) : (
              <RiMenu3Line className="text-3xl cursor-pointer text-gray-800" />
            )}
          </div>

          {/* Navigation Links */}
          <nav
            className={`space-x-8 text-sm font-semibold text-gray-800 md:flex ${
              isMenuOpen ? 'block' : 'hidden'
            } md:space-x-4 md:static absolute top-16 left-0 w-full bg-white md:bg-transparent md:w-auto p-4 md:p-0`}
          >
            {[
              { path: '/ventures', label: 'VENTURES' },
              { path: '/hub', label: 'HUBS' },
              { path: '/interventions', label: 'INTERVENTIONS' },
              { path: '/support', label: 'SUPPORT US' },
              { path: '/about', label: 'ABOUT US' },
            ].map((link) => (
              <Link
                key={link.path}
                to={link.path}
                onClick={handleLinkClick} // Close menu on click
                className={`block md:inline ml-4 pl-3 ${
                  location.pathname === link.path
                    ? 'text-blue-500 font-bold'
                    : 'hover:text-gray-500'
                }`}
              >
                {link.label}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
