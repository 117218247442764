import React, { useState } from "react";
import image1 from "../../assets/Group 22.png";
import image2 from "../../assets/Group 8.png";

// PartnerSection Component
const PartnerSection = ({ title, description, partners, onPartnerClick }) => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-green-600 text-center mb-4">{title}</h2>
        <p className="text-lg text-gray-600 text-center mb-8">{description}</p>

        {/* Logo Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center p-6 bg-white shadow-lg rounded-lg transition-transform hover:scale-105 cursor-pointer transform hover:shadow-xl"
              onClick={() => onPartnerClick(partner)}
            >
              <img
                src={partner.image}
                alt={partner.name}
                className="h-20 w-20 object-contain mb-4"
              />
              <p className="text-lg font-semibold text-gray-800">{partner.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Modal Component for Displaying Venture Details
const VentureModal = ({ isOpen, partner, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-3xl">
        <div className="flex">
          <img
            src={partner.image}
            alt={partner.name}
            className="w-1/3 h-1/3 object-cover rounded-lg mr-8"
          />
          <div className="flex flex-col justify-between w-2/3">
            <h3 className="text-2xl font-bold text-green-600 mb-4">{partner.name}</h3>
            <p className="text-lg text-gray-600 mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce egestas erat vel malesuada sollicitudin. Donec euismod metus vel massa pretium, at maximus ex volutpat.
            </p>
            <button
              className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition-colors"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// StartupsPage Component
const StartupsPage = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const startupPartners = [
    { name: "Startup 1", image: image1, description: "Description of Startup 1" },
    { name: "Startup 2", image: image2, description: "Description of Startup 2" },
    { name: "Startup 3", image: image1, description: "Description of Startup 3" },
    { name: "Startup 4", image: image2, description: "Description of Startup 4" },
    { name: "Startup 5", image: image1, description: "Description of Startup 5" },
    { name: "Startup 6", image: image2, description: "Description of Startup 6" },
    { name: "Startup 7", image: image1, description: "Description of Startup 7" },
    { name: "Startup 8", image: image2, description: "Description of Startup 8" },
    { name: "Startup 9", image: image1, description: "Description of Startup 9" },
    { name: "Startup 10", image: image2, description: "Description of Startup 10" },
  ];

  const smesPartners = [
    { name: "SME 1", image: image2, description: "Description of SME 1" },
    { name: "SME 2", image: image1, description: "Description of SME 2" },
    { name: "SME 3", image: image2, description: "Description of SME 3" },
    { name: "SME 4", image: image1, description: "Description of SME 4" },
    { name: "SME 5", image: image2, description: "Description of SME 5" },
    { name: "SME 6", image: image1, description: "Description of SME 6" },
    { name: "SME 7", image: image2, description: "Description of SME 7" },
    { name: "SME 8", image: image1, description: "Description of SME 8" },
    { name: "SME 9", image: image2, description: "Description of SME 9" },
    { name: "SME 10", image: image1, description: "Description of SME 10" },
  ];

  const coalitionPartners = [
    { name: "Coalition 1", image: image1, description: "Description of Coalition 1" },
    { name: "Coalition 2", image: image2, description: "Description of Coalition 2" },
    { name: "Coalition 3", image: image1, description: "Description of Coalition 3" },
    { name: "Coalition 4", image: image2, description: "Description of Coalition 4" },
    { name: "Coalition 5", image: image1, description: "Description of Coalition 5" },
    { name: "Coalition 6", image: image2, description: "Description of Coalition 6" },
    { name: "Coalition 7", image: image1, description: "Description of Coalition 7" },
    { name: "Coalition 8", image: image2, description: "Description of Coalition 8" },
    { name: "Coalition 9", image: image1, description: "Description of Coalition 9" },
    { name: "Coalition 10", image: image2, description: "Description of Coalition 10" },
  ];

  const filteredPartners = selectedFilter === "All"
    ? [...startupPartners, ...smesPartners, ...coalitionPartners]
    : selectedFilter === "Startups"
    ? startupPartners
    : selectedFilter === "SMEs"
    ? smesPartners
    : coalitionPartners;

  const handlePartnerClick = (partner) => {
    setSelectedPartner(partner);
  };

  const handleCloseModal = () => {
    setSelectedPartner(null);
  };

  return (
    <div className="bg-gray-50 flex flex-col lg:flex-row">
      <div
        className={`lg:w-1/6 w-full p-6 bg-white shadow-lg rounded-lg  mt-6 lg:mt-0 transition-all duration-300 ease-in-out ${
          isSidebarOpen ? "lg:w-1/4" : "lg:w-20"
        }`}
      >
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="lg:hidden text-green-600 p-2 mb-4"
        >
          {isSidebarOpen ? "Hide Filter" : "Show Filter"}
        </button>
        {isSidebarOpen && (
          <>
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Filter By</h3>
            <select
              className="px-4 py-2 w-full rounded-lg border border-gray-300 shadow-md"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Startups">Startups</option>
              <option value="SMEs">SMEs</option>
              <option value="Coalitions">Coalitions</option>
            </select>
          </>
        )}
      </div>

      <div className="lg:w-3/4 p-6 bg-gray-50">
        <PartnerSection
          title={selectedFilter}
          description={`Here are some of the most prominent ventures in the ${selectedFilter} category.`}
          partners={filteredPartners}
          onPartnerClick={handlePartnerClick}
        />
      </div>

      <VentureModal
        isOpen={!!selectedPartner}
        partner={selectedPartner}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default StartupsPage;
