import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm, clearMessages } from "../redux/contactSlice";
import contactImage from "../assets/busin-meet.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const initialFormState = {
    names: "",
    email: "",
    phone: "",
    questions: "",
    acceptPrivacyPolicy: false,
    subscribeNews: false,
  };

  const [formData, setFormData] = useState(initialFormState);

  const dispatch = useDispatch();
  const { loading, successMessage, errorMessage } = useSelector(
    (state) => state.contact
  );

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      setFormData(initialFormState);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
    dispatch(clearMessages());
  }, [successMessage, errorMessage, dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitContactForm(formData));
  };

  return (
    <section className="bg-white py-12 text-sm">
      <div className="container mx-auto flex flex-col md:flex-row">
        <ToastContainer />
        <div className="md:w-1/2 p-6">
          <h1 className="text-xl font-semibold mb-6">
            Go ahead and send us a Message!!
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="fullName"
              >
                Full name<span className="text-red-500">*</span>
              </label>
              <input
                id="names"
                name="names"
                type="text"
                placeholder="Full Name"
                value={formData.names}
                onChange={handleChange}
                required
                className="w-full border border-gray-300 p-3 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="email"
              >
                Email<span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Type your email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full border border-gray-300 p-3 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="phoneNumber"
              >
                Phone number <span className="text-gray-500">(optional)</span>
              </label>
              <div className="flex">
                <select
                  name="countryCode"
                  className="w-1/4 border border-gray-300 p-3 rounded-l-lg"
                  defaultValue="PL"
                >
                  <option value="PL">RW</option>
                  {/* Add more country codes as needed */}
                </select>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="+250 000-000-000"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-3/4 border-t border-r border-b border-gray-300 p-3 rounded-r-lg"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="message"
              >
                How we can help?<span className="text-red-500">*</span>
              </label>
              <textarea
                id="question"
                name="questions"
                placeholder="Your message"
                value={formData.questions}
                onChange={handleChange}
                required
                className="w-full border border-gray-300 p-3 rounded-lg"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  name="acceptPrivacyPolicy"
                  type="checkbox"
                  checked={formData.acceptPrivacyPolicy}
                  onChange={handleChange}
                  required
                  className="mr-2"
                />
                I've read and accept{" "}
                <a href="#" className="underline ml-2" style={{ color: '#3c7b24' }}>
                  privacy policy
                </a>
                <span className="text-red-500 ml-1">*</span>
              </label>
            </div>
            <div className="mb-6">
              <label className="flex items-center">
                <input
                  name="subscribeNews"
                  type="checkbox"
                  checked={formData.subscribeNews}
                  onChange={handleChange}
                  className="mr-2"
                />
                I'd like to get news and insights from us{" "}
                <span className="text-gray-500 ml-1">(optional)</span>
              </label>
            </div>
            <button
              type="submit"
              className="text-white px-6 py-3 rounded-lg hover:bg-green-600 transition-colors"
              style={{ backgroundColor: '#3c7b24' }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send message"}
            </button>
          </form>
        </div>
        <div className="md:w-1/2 rounded-tl-2xl rounded-br-lg overflow-hidden hidden md:block">
          <div className="h-full w-full">
            <img
              src={contactImage}
              alt="Contact us"
              className="object-cover h-full w-full rounded-tl-2xl rounded-br-lg"
            />
          </div>
        </div>

      </div>
    </section>
  );
};

export default ContactForm;
