import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { FaChevronDown } from 'react-icons/fa';

const TopHeader = () => {
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  return (
    <div className="bg-gray-900 text-white text-sm py-2 hidden md:block">
      <div className="mx-auto flex flex-col md:flex-row justify-between items-center px-4">
        {/* Social Media Icons - Hidden on Mobile */}
        <div className="hidden md:flex space-x-4">
          {/* Social Media Icons */}
          {/* ... */}
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col md:flex-row w-full md:w-auto md:space-x-4">
          <Link
            to="/events"
            className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0"
          >
            Events
          </Link>
          <Link
            to="/collaboration"
            className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0"
          >
            Collaborations
          </Link>
          {/* <div className="relative group">
            <button
              onClick={toggleDropdown}
              className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0 focus:outline-none flex items-center justify-center space-x-2"
            >
              <span>Collaborations</span>
              <FaChevronDown
                className={`w-4 h-4 transition-transform ${
                  isDropdownOpen ? 'rotate-180' : ''
                }`}
              />
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 bg-gray-800 text-white mt-2 rounded-md shadow-lg z-50">
                <Link
                  to="/collaboration"
                  className="block px-4 py-2 hover:bg-gray-700"
                >
                  Main Collaboration
                </Link>
                <Link
                  to="/collaboration/partners"
                  className="block px-4 py-2 hover:bg-gray-700"
                >
                  Partners
                </Link>
                <Link
                  to="/collaboration/sponsorship"
                  className="block px-4 py-2 hover:bg-gray-700"
                >
                  Sponsorship
                </Link>
              </div>
            )}
          </div> */}
          <Link
            to="/membership"
            className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0"
          >
            Become a member
          </Link>
          <Link
            to="/contact"
            className="hover:text-gray-300 w-full md:w-auto block text-center py-1 md:py-0"
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
