import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPartners } from "../../redux/partnerSlice";

const SkeletonLoader = () => (
  <div className="flex overflow-hidden w-full whitespace-nowrap pb-12">
    {[1, 2, 3, 4, 5].map((key) => (
      <div
        key={key}
        className="flex justify-center items-center px-4 min-w-[20%] sm:min-w-[15%] md:min-w-[10%] animate-pulse"
      >
        <div className="h-16 w-16 sm:h-20 sm:w-20 md:h-24 md:w-24 bg-gray-300 rounded-md shadow-md"></div>
      </div>
    ))}
  </div>
);

const PartnersSection = () => {
  const dispatch = useDispatch();
  const { partners, loading, error } = useSelector((state) => state.partners || {});
  const carouselRef = useRef(null);

  useEffect(() => {
    // Fetch partners on component mount
    dispatch(fetchPartners());
  }, [dispatch]);

  if (loading)
    return (
      <section className="py-12 bg-[#fff] px-6 md:px-12 lg:px-20">
        <h2 className="text-3xl md:text-4xl font-bold text-[#2bbae6] mb-10 font-big-shoulders text-left py-12">
          Our Partners
        </h2>
        <div className="container mx-auto items-center flex justify-center">
          <SkeletonLoader />
        </div>
      </section>
    );

  if (error)
    return (
      <section className="py-12 bg-[#fff] px-6 md:px-12 lg:px-20">
        <h2 className="text-3xl md:text-4xl font-bold text-[#2bbae6] mb-10 font-big-shoulders text-left py-12">
          Our Partners
        </h2>
        <div className="text-center text-red-500">Error: {error}</div>
      </section>
    );

  return (
    <section className="py-12 bg-[#fff] px-6 md:px-12 lg:px-20">
      <h2 className="text-3xl md:text-4xl font-bold text-[#2bbae6] mb-10 font-big-shoulders text-left py-12">
        Our Partners
      </h2>
      <div className="container mx-auto items-center flex justify-center overflow-hidden relative">
        <div
          ref={carouselRef}
          className="partners-carousel flex whitespace-nowrap animate-marquee"
        >
          {/* Render partner logos */}
          {partners &&
            [...partners, ...partners].map((partner, index) => (
              <div
                key={index}
                className="flex justify-center items-center px-4 min-w-[20%] sm:min-w-[15%] md:min-w-[12%] lg:min-w-[10%] py-2"
              >
                <img
                  src={partner.imageUrl || "https://via.placeholder.com/100"}
                  alt={partner.name || `Partner ${index + 1}`}
                  className="h-16 w-16 sm:h-20 sm:w-20 md:h-24 md:w-24 object-contain rounded-md shadow-md"
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
